<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="form-row">
            <div class="col-md-2">
              <select v-model="search_data.hub_id" v-validate="{ required: true }" id="hub_id" name="hub_id" class="form-control form-control-sm">
                <option value="all_hub">All hub</option>
                <option v-for="(hub, index) in hubs" :key="index" :value="hub.id">{{ hub.name }}</option>
              </select>
              <div class="invalid-feedback">Hub name is required</div>
            </div>
            <div class="col-md-2">
              <select v-model="search_data.month_id" v-validate="{ required: true }" id="month_id" name="month_id" class="form-control form-control-sm">
                <option selected disabled :value="''">Select a month</option>
                <option v-for="(month, index) in months()" :key="index" :value="index">{{ month }}</option>
              </select>
              <div class="invalid-feedback">Month is required</div>
            </div>
            <div class="col-md-2">
              <select v-model="search_data.year_id" v-validate="{ required: true }" id="year_id" name="year_id" class="form-control form-control-sm">
                <option selected disabled :value="''">Select a year</option>
                <option v-for="(year, index) in years()" :key="index" :value="index">{{ year }}</option>
              </select>
              <div class="invalid-feedback">Year is required</div>
            </div>
            <div class="col-md-2">
              <select v-model="search_data.type" v-validate="{ required: true }" id="type" name="type" class="form-control form-control-sm">
                <option selected disabled :value="''">Select a type</option>
                <option value="Inbound">Inbound</option>
                <option value="Consumption">Consumption</option>
                <option value="InboundConsumption">Inbound & Consumption</option>
              </select>
              <div class="invalid-feedback">Type is required</div>
            </div>
            <div class="col-md-2">
              <select v-model="search_data.download_file_tag" v-validate="{ required: true }" id="download_file_tag" name="download_file_tag" class="form-control form-control-sm">
                <option selected disabled :value="''">Select a download type</option>
                <option value="excel">Excel</option>
              </select>
              <div class="invalid-feedback">Type is required</div>
            </div>
            <div class="col-md-2">
              <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="pdfDownload()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Download</a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'

export default {
  name: 'List',
  data() {
    return {
      hubs: [],
      index: -1,
      pdfDownloadLoader: false,
      loading: false,
      btnLoading: false,
      loader: false,
      flag: false,
      search_data: {
        hub_id: 'all_hub',
        month_id: '',
        year_id: '',
        type: '',
        download_file_tag: '',
      },
    }
  },
  mounted() {
    this.codes()
  },
  methods: {
    codes() {
      apiClient.get('api/user/codes')
        .then(response => {
          const data = response.data
          this.hubs = data.hubs
        })
        .catch(error => {
          console.log(error)
        })
    },
    pdfDownload(index) {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.btnLoading = true
          apiClient.post('api/report/monthly-expense/download', this.search_data, { responseType: 'blob' }).then(response => {
            this.btnLoading = false
            if (response.data.size === 68) {
              this.$notification.error({
                message: 'No data found, in this search query',
              })
            } else {
              const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }))
              const link = document.createElement('a')
              link.href = url
              if (this.search_data.download_file_tag === 'pdf') {
                link.setAttribute('download', 'hub_monthly_report.pdf')
              }
              if (this.search_data.download_file_tag === 'excel') {
                link.setAttribute('download', 'hub_monthly_report.xlsx')
              }
              document.body.appendChild(link)
              link.click()
            }
          }).catch(error => {
            console.log(error)
          })
        }
      })
    },
    months() {
      return this.$months()
    },
    years() {
      return this.$years()
    },
  },
}
</script>

<style scoped>
.form-control-sm {
  height: calc(1.7em + .5rem + 2px) !important;
}
</style>
